import "./scss/theme.scss"

import Vue from 'vue'
import App from './App.vue'

import { NavPlugin } from 'bootstrap-vue'
Vue.use(NavPlugin)

import {BRow, BCol, BButton, BFormSelect, BSidebar, BCollapse} from 'bootstrap-vue'
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-button', BButton)
Vue.component('b-btn', BButton)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-sidebar', BSidebar)
Vue.component('b-collapse', BCollapse)

import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
window.axios = axios

Vue.config.productionTip = false

import router from './router'
import store from './store'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import {
  faBars,
  faBicycle,
  faCaretRight,
  faCartShopping,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faEnvelope,
  faEuro,
  faHandshake,
  faHeart,
  faMapMarker,
  faMapMarkerAlt,
  faPhone,
  faSpinner
} from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(
    faBars,
    faBicycle,
    faCartShopping,
    faEuro,
    faHeart,
    faPhone,
    faEnvelope,
    faHandshake,
    faCaretRight,
    faChevronRight,
    faChevronDown,
    faMapMarker,
    faMapMarkerAlt,
    faCheckCircle,
    faSpinner,
)
/* add font awesome icon component */
Vue.component('fa-icon', FontAwesomeIcon)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
