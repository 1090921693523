<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    name: 'App',
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Hochwertige Lastenräder in München mieten',
        // all titles will be injected into this template
        titleTemplate: '%s | bigRad'
    },
    data: () => ({
        store: {}
    }),

    beforeMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    created() {

    }
};
</script>
