import * as types from '../mutation-types'

export const state = {
    items: [],
}

export const getters = {
    items: state => state.items,
}

export const mutations = {
    [types.SET_CART_ITEMS](state, payload) {
        state.items = payload
    },
}

export const actions = {
    clear({commit}) {
        commit(types.SET_CART_ITEMS, [])
    },
    addItem({state, commit}, payload) {
        commit(types.SET_CART_ITEMS, [payload])
    },
}
